(function($) {$.fn.inviewClass = function(options){var settings = $.extend({className: 'is-inview', secondClassName: 'is-inview-done', visibleOffset: 200, secondClassInterval: 1000 }, options); var isDisabled = false; if(settings.SecondClassName === ''){isDisabled = true; } return this.each(function(){var $this = $(this); function inviewClass(){let currentPos = pageYOffset; if(currentPos > $this.offset().top - window.innerHeight + settings.visibleOffset){if(!$this.hasClass(settings.className)){$this.addClass(settings.className); if(!isDisabled){var thisTimeoutID = setTimeout(function(){$this.addClass(settings.secondClassName); clearTimeout(thisTimeoutID); }, settings.secondClassInterval); } } } } inviewClass(); $(window).on('resize', function(){inviewClass(); }); $(window).on('scroll', function(){inviewClass(); }); }) } })(jQuery);

const im = [];
im.body = document.querySelector('body');
im.header = document.querySelector('.siteheader');
im.headerNav = document.querySelector('.siteheader__nav');
im.breakPoint = 600;
im.bodyOffsetPos;
im.container = document.querySelector('.l-main');

im.initparallax = function(){

  if(im.body.querySelector('.js-parallax') == null) {
    return;
  }

  let rellax,
      rellaxNocenter;

  const parallaxBreakPointArray = [600, 1080, 1440];
  
  /*
  default: data-rellax-speed="0"
  600px以下: data-rellax-xs-speed="0"
  600px ~ 1080px: data-rellax-mobile-speed="0"
  1080px ~ 1440px: data-rellax-tablet-speed="0"
  1440px以上 : data-rellax-desktop-speed="10"
  */

  rellax = new Rellax('.js-parallax', {
    breakpoints: parallaxBreakPointArray,
    center:true
  })

  rellaxNocenter = new Rellax('.js-parallax-nocenter', {
    breakpoints: parallaxBreakPointArray,
    center:false
  })

  $('.js-parallax-trigger').each(function(){
    const $this = this,
          thisTarget = $this.querySelector('.js-parallax-target');

    function scrollFunc(){
      if(thisTarget !== null){
        thisTarget.style.transform = $this.style.transform;
      }

    }
    scrollFunc()

    //requestAnimationFrameを使ってscrollを間引く
    var ticking = false;
    function func() {
      if (!ticking) {
        requestAnimationFrame(function () {
          ticking = false;
          scrollFunc()
        });
        ticking = true;
      }
    }
    document.addEventListener("scroll", func, { passive: true });

  })
}
im.initparallax();


im.initInviewClass = function(){
  $('.js-inview').inviewClass({
    visibleOffset: 100,
    secondClassInterval: 1000
  });

  $('.js-inview-fadein').inviewClass({
    visibleOffset: 100,
    secondClassInterval: 1000
  });

  $('.js-inview-clip').inviewClass({
    visibleOffset: 150,
    secondClassInterval: 1000
  });

  $('.js-inview-cover').inviewClass({
    visibleOffset: 150,
    secondClassInterval: 1000
  });

  $('.js-inview-imagecover').inviewClass({
    visibleOffset: 400,
    secondClassInterval: 1000
  });

  $('.js-inview-container').inviewClass({
    visibleOffset: 150,
    secondClassInterval: 1000
  });
}
im.initInviewClass();


im.lazyload = function(){

  let currentPos = pageYOffset,
      lazyloadThreshold = 200;

  function doLazyload(){
    currentPos = pageYOffset;

    $('.js-lazyload', im.container).each(function(){

      let $this = $(this);
      if(currentPos > $this.offset().top - window.innerHeight - lazyloadThreshold){

        if(!$this.hasClass('js-lazyload-done')){

          $this.addClass('js-lazyload-done');
          $this.attr('src', $this.data('src'));

        }

      }
    })
  }
  doLazyload();

  $(window).on('resize scroll', function(){
    doLazyload();
  })

}
im.lazyload();


im.anchorTo = (targetID, callback) => {

  if(!targetID){
    return;
  }

  let $target = im.body.querySelector('#' + targetID),
      waitTime = 0,
      thisOffset = 0;

  $.Velocity($target, 'scroll', {
    duration: 800,
    easing: "easeInOutCubic",
    offset: thisOffset,
    delay: waitTime,
    complete: ()=> {
      if(callback){
        callback();
      }
      im.anchorTarget = false;
    }
  })
}


im.initPageTop = () => {

  if(document.querySelector('.c-pagetop') == null) {
    return;
  }

  const pageTopElem = document.querySelector('.c-pagetop'),
        topElement = document.querySelector('#barba-wrapper');

  pageTopElem.addEventListener('click', (event)=> {
    event.preventDefault();
    $.Velocity(topElement, 'scroll', {
      duration: 800,
      easing: "easeInOutCubic",
      offset: 0,
      delay: 0,
      complete: ()=> {
        im.anchorTarget = false;
      }
    })
  })
}
im.initPageTop();


im.initAnchorlink = function(){

  $('.js-anchor').each(function(){
    const $this = $(this);
    $this.on('click', function(event){
      event.preventDefault();
      const targetID = $this.attr('href').split('#')[1];
      im.anchorTo(targetID);
    })
  })
}
im.initAnchorlink();


im.initMenu = function(){

  const menuIcon = document.querySelector('.js-menu-icon'),
        menu = document.querySelector('.js-menu-body'),
        menuBody = document.querySelector('.js-menu-body'),
        menuOverlay = document.querySelector('.js-menu-overlay'),
        menuContent = document.querySelector('.js-menu-content');

  im.openMenu = function(){

    menuIcon.style.pointerEvents = 'none';

    im.body.classList.add('is-menu-open')
    menuBody.style.display = 'block';
    menuContent.style.opacity = 0;

    $.Velocity(menuOverlay, {
      translateX: [0, window.innerWidth * -1]
    }, {
      duration: 600,
      easing: 'easeOutExpo',
      begin: ()=> {
        setTimeout(()=> {
          $.Velocity(menuContent, {
            opacity: 1
          }, {
            duration: 400,
            easing: 'easeOutQuad',
            complete: function(){
              menuIcon.style.pointerEvents = '';
            }
          })
        }, 100);
      }
    })
  }

  im.closeMenu = function(){
    menuIcon.style.pointerEvents = 'none';
    $.Velocity(menuContent, {
      opacity: 0
    }, {
      duration: 200,
      easing: 'easeOutQuad',
      begin: ()=> {
        im.body.classList.remove('is-menu-open')
        setTimeout(()=> {
          $.Velocity(menuOverlay, {
            translateX: [window.innerWidth, 0]
          }, {
            duration: 400,
            easing: 'easeOutQuart',
            complete: ()=> {
              menuBody.style.display = 'none';
              menuIcon.style.pointerEvents = '';
            }
          })
        }, 0);
      }
    })
  }

  menuIcon.addEventListener('click', ()=> {
    if(!im.body.classList.contains('is-menu-open')){
      im.openMenu();
    } else {
      im.closeMenu();
    }
  })

  const menuLinkArray = menuBody.querySelectorAll('a')
  for(let i = 0; i < menuLinkArray.length; i++){
    menuLinkArray[i].addEventListener('click', ()=> {
      im.closeMenu();
    })
  }
}
im.initMenu();


im.initScrollFunc = ()=> {
  const header = document.querySelector('.siteheader'),
        footer = document.querySelector('.sitefooter'),
        $statementSection = $('#statement'),
        $companySection = $('#company');

  const scrolledOffset = 100;

  let currentPos = window.pageYOffset;

  function scrollFunc() {
    currentPos = window.pageYOffset;

    if(currentPos > scrolledOffset){
      if(!im.body.classList.contains('is-scrolled')) {
        im.body.classList.add('is-scrolled')
      }
    } else {
      if(im.body.classList.contains('is-scrolled')) {
        im.body.classList.remove('is-scrolled')
      }
    }

    if(currentPos > $statementSection.offset().top - window.innerHeight * 0.333){
      if(!im.body.classList.contains('is-statement-shown')){
        im.body.classList.add('is-statement-shown')
      }
    } else {
      if(im.body.classList.contains('is-statement-shown')){
        im.body.classList.remove('is-statement-shown')
      }
    }

    if(currentPos > $companySection.offset().top - window.innerHeight){

      if(!im.body.classList.contains('is-company-shown')){
        im.body.classList.add('is-company-shown')
      }
    } else {
      if(im.body.classList.contains('is-company-shown')){
        im.body.classList.remove('is-company-shown')
      }
    }

  }
  scrollFunc()

  $(window).on('resize scroll', function(){
    scrollFunc()
  })

}
im.initScrollFunc();


im.setPartsLayout = function(){

  const randomArray = [0, .2, .6, .8, 1.2];

  let lastNum

  function randomNum(min, max){
    return parseInt(Math.random() * ( max - min ) + min);
  }

  $('.c-instagramgrid__item--image').each(function(){

    const $this = $(this);
    let nextIndex = randomNum(0, randomArray.length);

    //一つ前の数値と同じだったら一度だけランダム数値を取り直す
    if(nextIndex === lastNum){
      nextIndex = randomNum(0, randomArray.length);
    }

    this.style.transitionDelay = randomArray[nextIndex] + 's';

    lastNum = nextIndex;
  })
}

window.addEventListener('DOMContentLoaded', ()=> {
  im.setPartsLayout();
});


im.initModal = function(){

  let $modalBody = $('.sitemodal'),
      $modalOverlay = $('.sitemodal__overlay'),
      $modalContent;

  const $modalClose = document.querySelector('.js-modal-close')

  let speed = 400;

  function openModal(target){

    if(!im.body.classList.contains('is-modal-open')){

      $modalContent = $('.sitemodal__content[data-modal="'+target+'"]');
      $modalContent.show().css({'opacity': '0'});

      if(!im.body.classList.contains('is-menu-open')){
        im.bodyOffsetPos = window.pageYOffset;
      }

      im.body.classList.add('is-modal-open');
      $modalContent.css({'opacity': '0'});
      $modalOverlay.css({'transform': 'translateX(' + window.innerWidth + 'px)'});

      $modalOverlay.velocity({
        translateX: [0, window.innerWidth * -1],
      }, {
        easing: 'easeOutExpo',
        duration: 600,
        begin: function(){
          $modalContent.delay(100).velocity({
            opacity: 1,
          }, {
            easing: 'easeOutQuad',
            duration: 400,
          })
        }
      })
    }
  }

  function closeModal(){

    if(im.body.classList.contains('is-modal-open')){

      $(window).scrollTop(im.bodyOffsetPos);

      $modalOverlay.velocity({
        translateX: [window.innerWidth, 0],
      }, {
        easing: 'easeOutQuart',
        duration: 400,
        begin: function(){

          $modalContent.delay(10).velocity({
            opacity: 0,
          }, {
            easing: 'easeOutCubic',
            duration: 200,
          })
        },
        complete: function(){

          im.body.classList.remove('is-modal-open')
          $modalContent.hide();

        }
      })
    }
  }

  $('.js-modal-close').each(function(){
    let $this = $(this);

    $this.on('click', function(event){
      event.preventDefault();
      closeModal()
    })
  })

  $modalOverlay.on('click', function(event){

    event.preventDefault();
    closeModal()
  })

  $('.js-modal-open').each(function(){
    let $this = $(this);

    $this.on('click', function(event){
      event.preventDefault();
      openModal($this.data('modal'));
    })
  })
}
im.initModal();


im.setHeroSize = function(){

  const $heroSection = $('.hero');

  let windowWidth,
      windowHeight;


  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  function setSectionSize(){
    $heroSection.height(windowHeight);
  }
  setSectionSize()

  let resizeTimer = null;
  $(window).on('resize', function(){
    clearTimeout( resizeTimer );
    resizeTimer = setTimeout(function() {

      windowHeight = window.innerHeight;

      if(window.innerWidth > im.breakPoint){
        setSectionSize()
        windowWidth = window.innerWidth;
      } else {
        if(window.innerWidth !== windowWidth) {
          setSectionSize()
          windowWidth = window.innerWidth;
        }
      }
    }, 300 );
  })

}
im.setHeroSize();

im.setCompanyBgElem = function(){

  /*
  モバイルでガタつき防止のため、company背景動画、THINKR / AHA の動画をそれぞれの項目の外、セクション直下に置く。
  サイズとポジションをJSで設定する
  */
  const bgItemCompany01 = $('.js-bgitem-companylist--01'),
        bgItemCompany02 = $('.js-bgitem-companylist--02'),
        companyItem01 = $('.companylist__item--01'),
        companyItem02 = $('.companylist__item--02'),
        companySection = $('.company');

  const topOffset = 60;

  function setBgElemLayout(){
    if(window.innerWidth > im.breakPoint){
      return;
    }
    bgItemCompany01.height(companyItem01.innerHeight())
    bgItemCompany01.css({'top': (companyItem01.offset().top - companySection.offset().top - topOffset) + 'px'})

    bgItemCompany02.height(companyItem02.innerHeight())
    bgItemCompany02.css({'top': (companyItem02.offset().top - companySection.offset().top - topOffset) + 'px'})

  }
  setBgElemLayout()

  window.addEventListener('scroll', ()=> {
    setBgElemLayout()
  })

  window.addEventListener('resize', ()=> {
    setBgElemLayout()
  })

}
im.setCompanyBgElem();


im.initMovies = function(){

  const heroMovie = im.body.querySelector('#heromovie'),
        heroSection = im.body.querySelector('#hero');

  const options = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "0% 0%", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const observer = new IntersectionObserver(toggleMovie, options);

  function toggleMovie(entries){

    if(entries[0].isIntersecting){
      if(heroMovie.paused){
        heroMovie.play();
      }
    } else {
      if(!heroMovie.paused){
        heroMovie.pause();
      }
    }

  }

  function initHeroMovie(){
    setTimeout(function(){
      im.body.classList.add('is-logo-show')

      setTimeout(function(){  
        observer.observe(heroSection);
      }, 1000);

      setTimeout(function(){
        if(window.innerWidth > im.breakPoint) {
          initBgMovie()
        }
      }, 1500);
    }, 1000);
  }

  heroMovie.addEventListener('canplay', initHeroMovie)
  heroMovie.load()
  if(heroMovie.readyState > 3){
    initHeroMovie();
  }

  function initBgMovie(){
    $('.bg-movie-wide').each(function(){

      let thisMovie = this;
      thisMovie.setAttribute('src', thisMovie.dataset.src);

      //playしたらandroidで落ちる
      thisMovie.addEventListener('canplay', function(){
        if($('.ua-android').length < 1){
          thisMovie.play();
        }
      })
      //thisMovie.load();
    })
  }
}


im.webfontLoad = function(){
  $('head').append('<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&family=Noto+Sans+SC:wght@700&display=swap" rel="stylesheet">');
}


const minLogoShowTime = 5000;

setTimeout(()=> {
  if(!im.body.classList.contains('is-logo-show')){
    im.body.classList.add('is-logo-show')
  }
}, minLogoShowTime);


window.addEventListener('DOMContentLoaded', ()=> {
  im.body.classList.add('is-ready')

  $(window).on('load', ()=> {
    im.webfontLoad();
  })

  im.initMovies();
})
